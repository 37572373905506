import React from 'react'
import './About.scss'
const About = () => {

  return (
    <div className='about'>

    </div >
  )
}

export default About